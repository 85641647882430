<script lang="ts">
	import { messagesStore } from '../../../../stores/messagesStore';
	import { sendClientMsg } from '../../../../infra/ChatAPI';
	import { _ } from '../../../../infra/services/i18n';
	import { fly } from 'svelte/transition';
	import { confirmationWindowsStore } from '../../../../stores/confirmationWindowsStore';
	import File from '../../../svg/File.svelte';

	export let changeStatePopUp: Function;
	let visibility: boolean;

	confirmationWindowsStore.subscribe(v => visibility = v);

	enum typesFile {
		file = 1,
		image = 2
	}

	const maxSizeFile: number = 5242880;
	let files: FileList;
	let fileArray: Array<any>;
	let userId: string;
	let fileType: number;

	const processFile = async (type: number) => {
		try {
			confirmationWindowsStore.set(false);
			changeStatePopUp();
			const messages = [];
			const warnings = [];
			for (const file of fileArray) {
				let finalType = type;
				const result = await getBase64Format(file);
				const fileType = file.type.split('/')[0];
				if (finalType === typesFile.image && fileType !== 'image') finalType = typesFile.file;
				const name = file.name.replace('C:\\fakepath\\', '');
				if (file.size > maxSizeFile) {
					warnings.push(`•${name}`);
					continue;
				}
				const text = `name:{${name}},dataUrl:{${result}}`;
				const respMessage = await sendMsg(text, finalType);
				messages.push(respMessage);
			}
			messagesStore.update(oldMsgs => [...oldMsgs, ...messages]);
			showWarningIfAny(warnings);
		} catch (e) {
			console.log(e);
		} finally {
			files = null;
			fileArray = [];
		}
	};

	const getBase64Format = (file: File) => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = error => reject(error);
		});
	};

	const sendMsg = (text, type) => {
		return sendClientMsg(text, type);
	};

	const showWarningIfAny = (warnings: Array<string>) => {
		if (warnings.length != 0) {
			const files = warnings.join(',\n');
			alert(`${$_('app.footerComponent.error_file_size')}\n${files}`);
		}
	};

	const removeFile = (i) => {
		fileArray = fileArray.filter((file, index) => i !== index);
		if (fileArray.length === 0) {
			files = null;
			confirmationWindowsStore.set(false);
			changeStatePopUp();
		}
	};
</script>

<div class="files__container" transition:fly="{{ y: 0, duration: 200 }}">
	<label for="file-all" class="custom-file-upload-svelte">
		{$_('app.footerComponent.input_file')}
	</label>
	<input id="file-all" type="file" bind:files on:change={() => {
		confirmationWindowsStore.set(true);
		fileArray = Array.from(files);
		fileType = typesFile.file;
	}} multiple />
	<label for="file-image" class="custom-file-upload-svelte">
		{$_('app.footerComponent.input_image')}
	</label>
	<input id="file-image" type="file" accept="image/*" bind:files on:change={() => {
		confirmationWindowsStore.set(true);
		fileArray = Array.from(files);
		fileType = typesFile.image;
	}} multiple />
</div>

{#if visibility}
	<div class="confirmation__windows-svelte">
		<div class="background_svelte"></div>
		<div class="popUp_confirmation__windows-svelte">
			<div class="popUp_confirmation-files-svelte">
				{#each fileArray as { name }, i}
					<div class="popUp_confirmation-file-svelte">
						<File />
						<div class="popUp_confirmation-name-svelte" title={name}>{name}</div>
						<svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" class="svg_delete_file" on:click={() => {
							removeFile(i);
						}}>
							<path d="M7 7L23 23" stroke="#000" stroke-width="2"/>
							<path d="M23 7L7 23" stroke="#000" stroke-width="2"/>
						</svg>
					</div>
				{/each}
			</div>
			<div class="popUp_confirmation-btns-svelte">
				<div class="button-svelte"
					 on:click={() => {
						files = null;
						confirmationWindowsStore.set(false);
						changeStatePopUp();
					}}
				>
					{$_('app.footerComponent.confirmation_windows_close')}
				</div>
				<div class="button-svelte"
					 on:click={() => { processFile(fileType) }}
				>
					{$_('app.footerComponent.confirmation_windows_send')}
				</div>
			</div>
		</div>
	</div>
{/if}

<style>
    .files__container {
        position: absolute;
        bottom: 40px;
        left: 5px;
        min-width: 80px;
        width: fit-content;
        height: 70px;
        background: #fff;
		border-radius: 5px;
		box-shadow: 0 3px 8px 1px rgb(0 0 0 / 13%), 0 0.6px 2px rgb(0 0 0 / 10%);
        display: flex;
        flex-direction: column;
        align-items: center;
		color: black;
    }

    input[type="file"] {
        display: none;
    }

    label.custom-file-upload-svelte {
		width: -webkit-fill-available;
        text-align: center;
        cursor: pointer;
        height: 100%;
        display: flex;
        align-items: center;
		padding: 0 10px;
		margin-bottom: 5px;
		font-size: 15px;
		letter-spacing: .4px;
		color: black;
		font-weight: 500;
    }

	.custom-file-upload-svelte:nth-child(1) {
		margin-top: 5px;
		margin-bottom: 0;
	}

    label.custom-file-upload-svelte:hover {
		transition: 350ms all linear;
        background: #e6e3e3;
    }

	.confirmation__windows-svelte {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;
		position: fixed;
		z-index: 9999999999;
		top: 0;
		left: 0;
	}

	.background_svelte {
		position: fixed;
		background: rgba(0, 0, 0, .5);
		width: 100%;
		height: 100%;
		margin: 0;
		padding: 0;
	}

	.popUp_confirmation__windows-svelte {
		width: 330px;
		background: white;
		z-index: 999999999;
		border-radius: 6px;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		padding: 20px;
		text-align: center;
		position: relative;
	}

	.popUp_confirmation-btns-svelte {
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		align-items: center;
		padding-top: 30px;
	}

	.button-svelte {
		margin-left: 25px;
		cursor: pointer;
		font-size: 16px;
		letter-spacing: .4px;
		color: #40a7e2;
		font-weight: 500;
	}

	.button-svelte:hover {
		color: #1c82c1;
		transition: 200ms all linear;
	}

	.popUp_confirmation-files-svelte {
		width: 100%;
	}

	.popUp_confirmation-file-svelte {
		display: flex;
		flex-direction: row;
		position: relative;
		justify-content: flex-start;
		align-items: center;
		margin-bottom: 10px;
	}

	.popUp_confirmation-name-svelte {
		margin-left: 12px;
		font-size: 14px;
		letter-spacing: .4px;
		color: #333232;
		font-weight: 600;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		width: 80%;
		text-align: start;
	}

	svg.svg_delete_file {
		width: 20px;
		height: 20px;
		position: absolute;
		right: 5px;
		cursor: pointer;
	}
</style>
