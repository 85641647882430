<script lang="ts">
	import { callStateStore } from '../../stores/callStateStore';
	import { messagesStore } from '../../stores/messagesStore';
	import { stateChat } from '../../stores/stateChat';
	import SvgMessage from '../svg/SvgMessage.svelte';
	import SvgSupport from '../svg/SvgSupport.svelte';
	import SvgAnswer from '../svg/SvgAnswer.svelte';
	import { CallerStateType } from '../../types';
	import type { Message } from '../../types';
	import SvgStop from '../svg/SvgStop.svelte';
	import { onDestroy } from 'svelte';

	export let style: Object;
	let messages: Array<Message>;
	let unreadMsgCount: number = 0;
	let callState: number;

	$: {
		let count: number = 0;
		for (const message of messages) {
			if (message.status == 'unread' && message.sender_id === 'CRM') count++;
		}
		unreadMsgCount = count;
	}

	const unsubscribeCallStateStore = callStateStore.subscribe(v => callState = v);
	const unsubscribeMessagesStore = messagesStore.subscribe(v => messages = v);

	const changeState = () => {
		stateChat.update(v => !v);
		if (callState === CallerStateType.CONNECTING) {
			callStateStore.set(CallerStateType.SPEAKING);
		}
	};

	const stopCall = () => {
		callStateStore.set(CallerStateType.STOP_CALL);
		stateChat.update(v => !v);
	};

	onDestroy(() => {
		unsubscribeCallStateStore();
		unsubscribeMessagesStore();
	});
</script>

{#if callState === CallerStateType.SPEAKING || callState === CallerStateType.CONNECTING}
	<div class="speaking_stop {style.position}" on:click={stopCall}>
		<SvgStop />
	</div>
{/if}

<div class="iconChat {style.position}"
	 on:click={changeState}
	 class:pulse={callState === CallerStateType.CONNECTING}
	 class:call={callState === CallerStateType.CONNECTING || callState === CallerStateType.SPEAKING}
>
	{#if callState === CallerStateType.STAND_BY}
		<SvgMessage />
	{:else if callState === CallerStateType.CONNECTING}
		<SvgAnswer />
	{:else if callState === CallerStateType.SPEAKING}
		<SvgSupport />
	{/if}
	<div class="count-message"
		 class:hidden={unreadMsgCount === 0 || callState === CallerStateType.CONNECTING}>{unreadMsgCount}</div>
</div>


<style>
	.iconChat {
		width: 65px;
		height: 65px;
		position: fixed;
		border-radius: 50%;
		background: #2196f3;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		box-shadow: #2196f3 inset;
		box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
		z-index: 9999;
	}

	.iconChat.left {
		bottom: 20px;
		left: 20px;
	}

	.iconChat.right {
		bottom: 20px;
		right: 20px;
	}

	.iconChat.right.call {
		bottom: 20px;
		right: 95px;
	}

	.count-message {
		position: absolute;
		border-radius: 50%;
		width: 35%;
		height: 35%;
		background: rgb(255, 90, 93);
		border: 1px solid #fff;
		top: -8px;
		left: 45px;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 10px;
		font-weight: 600;
		color: #fff;
	}

	.count-message.hidden {
		display: none;
	}

	.iconChat.pulse {
		background: linear-gradient(0deg, #1fcd46 0%, #b0f589 100%) !important;
		box-shadow: 0 0 0 0 #b0f589;
		transform: scale(1);
		animation: pulse 2s infinite;
	}

	.speaking_stop {
		width: 65px;
		height: 65px;
		position: fixed;
		border-radius: 50%;
		background: rgb(255, 59, 47);
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
		z-index: 9999;
	}

	.speaking_stop.right {
		bottom: 20px;
		right: 20px;
	}

	.speaking_stop.left {
		bottom: 20px;
		left: 95px;
	}

	.speaking_stop:hover,
	.iconChat:hover {
		transform: scale(1.03);
		transition: all .3s cubic-bezier(.47, 0, .745, .715);
	}

	@keyframes pulse {
		0% {
			transform: scale(0.95);
			box-shadow: 0 0 0 0 rgba(153, 239, 127, 0.7);
		}

		70% {
			transform: scale(1);
			box-shadow: 0 0 0 10px rgba(153, 239, 127, 0);
		}

		100% {
			transform: scale(0.95);
			box-shadow: 0 0 0 0 rgba(153, 239, 127, 0);
		}
	}
</style>
