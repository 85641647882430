export default {
    app: {
        headerComponent: {
            btn_answer_call: 'Ответить',
            btn_decline_call: 'Отказать',
            btn_stop_call: 'Остановить',
        },
        bodyComponent: {
            error_disconnected: 'Нет соединения. Идёт переподключение.',
            error_unauthorized: 'Время сессии истекло. Попробуйте перелогинитюся.',
            edited: 'отредактировано',
        },
        footerComponent: {
            btn_send: 'Отправить',
            input_file: 'Файл',
            input_image: 'Изображение',
            error_file_size: 'Файлы не могут быть загружены, так как размер превышает допустимый размер - 5 МБ:',
            confirmation_windows_close: 'Отмена',
            confirmation_windows_send: 'Отправить',
            quoted: 'Цитирование:',
        },
        warningMicrophone: {
            title: 'Доступ к микрофону запрещен',
            text: 'Пожалуйста разрешите доступ к микрофону.<br/>Для этого в адресной строке баузера нажмите на значок замка в ее начале или на значок камеры вконце строки. В появившемся окне разрешите доступ к микрофону.',
            btn_close: 'Закрыть',
        },
        systemMessage: {
            svc_call_started: 'Звонок начат',
            svc_call_ended: 'Звонок завершен',
            svc_call_rejected: 'Звонок отклонен',
            svc_call_missed: 'Пропущенный звонок',
            svc_switch_to: '{name} теперь Ваш менеджер',
            svc_delete_message: 'Сообщение удалено',
            svc_delete_history: 'Менеджер очистил историю',
        },
        fileMessage: {
            error_text: 'Файл поврежден',
        },
        helloMessage: {
            title: 'Добро пожаловать',
            sub_title: 'Чем мы можем вам помочь?',
        },
        contextMenu: {
            quote: 'Цитировать',
            edit: 'Редактировать',
            cancel: 'Отмена',
        },
    },
};
