export default class Index {
    constructor({ url, responseRouter, wsReConnector, unauthorizedMsg = 'unauthorized', debug = false }) {
        this.disconnectedCb = () => {
        };
        this.connectedCb = () => {
        };
        this.unauthorizedCb = () => {
        };
        this.unauthorizedMsg = unauthorizedMsg;
        this.isConnected = false;
        if (url.length === 0) {
            throw new Error('url is empty');
        }
        this.url = url;
        if (this.router === null) {
            throw new Error('router is null');
        }
        this.router = responseRouter;
        this.router.setConnectionCheck(() => this.isConnected);
        this.reConnector = wsReConnector;
        this.debug = debug;
    }
    connect() {
        if (this.isConnected) {
            console.log('already connected');
        }
        this.ws = new WebSocket(this.url);
        window.addEventListener('beforeunload', () => this.ws.close());
        if (this.debug)
            window['ws'] = this.ws;
        this.ws.onerror = (e) => console.log(e);
        this.ws.onclose = () => this.disconnected();
        this.ws.onopen = () => {
            this.connected();
        };
    }
    connected() {
        this.isConnected = true;
        if (this.debug)
            console.log('connected');
        this.reConnector.setConnectCb(() => this.connect());
        this.reConnector.setSendCb((data) => this.ws.send(data));
        this.router.setSendCb((data) => this.ws.send(data));
        this.connectedCb();
        this.ws.onmessage = ev => {
            switch (ev.data) {
                case this.unauthorizedMsg:
                    this.unauthorized();
                    break;
                default:
                    this.router.dataReceived(ev.data);
            }
        };
    }
    disconnected() {
        this.isConnected = false;
        if (this.debug)
            console.log('disconnected');
        const auth = this.disconnectedCb();
        this.reConnector.disconnected(auth);
    }
    unauthorized() {
        this.reConnector.stopReconnect();
        this.unauthorizedCb();
    }
    onDisconnected(cb) {
        this.disconnectedCb = cb;
    }
    onConnected(cb) {
        this.connectedCb = cb;
    }
    onUnauthorized(cb) {
        this.unauthorizedCb = cb;
    }
}
