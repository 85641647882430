export default {
    app: {
        headerComponent: {
            btn_answer_call: 'Odpowiedź',
            btn_decline_call: 'Upadek',
            btn_stop_call: 'Zatrzymać',
        },
        bodyComponent: {
            error_disconnected: 'Brak połączenia. Próbuję połączyć się ponownie.',
            error_unauthorized: 'Sesja wygasła. Spróbuj zalogować się ponownie.',
            edited: 'edytowane',
        },
        footerComponent: {
            btn_send: 'Wysłać',
            input_file: 'Plik',
            input_image: 'Wizerunek',
            error_file_size: 'Nie można przesłać plików, ponieważ ich rozmiar jest większy niż dozwolony - 5 MB:',
            confirmation_windows_close: 'Anuluj',
            confirmation_windows_send: 'Wysłać',
            quoted: 'Cytat:',
        },
        warningMicrophone: {
            title: 'Odmowa dostępu do mikrofonu',
            text: 'Zezwól na dostęp do mikrofonu.<br/>W tym celu w pasku adresu przeglądarki kliknij ikonę kłódki na jej początku lub ikonę aparatu na końcu linii. W wyświetlonym oknie zezwól na dostęp do mikrofonu.',
            btn_close: 'Blisko',
        },
        systemMessage: {
            svc_call_started: 'Połączenie',
            svc_call_ended: 'Połączenie zakończone',
            svc_call_rejected: 'Połączenie odrzucone',
            svc_call_missed: 'Nieodebrane połączenie',
            svc_switch_to: 'Przełączony {name}',
            svc_delete_message: 'Wiadomość usunięta',
            svc_delete_history: 'Menedżer wyczyścił historię',
        },
        fileMessage: {
            error_text: 'Plik jest uszkodzony',
        },
        helloMessage: {
            title: 'Witamy',
            sub_title: 'Jak możemy ci pomóc?',
        },
        contextMenu: {
            quote: 'Cytować',
            edit: 'Edytować',
            cancel: 'Anulowanie',
        },
    },
};
