<script lang="ts">
	import type { Message } from '../../../../types';
	import Citation from '../../../svg/Citation.svelte';
	import { formatMsgDisplayTime } from '../index';

	export let message: Message;
</script>

<div class="quoted-svelte">
    <div class="citation">
        <Citation />
        <div class="citation__text">{message.quoted_msg.text}</div>
        <div class="citation__time">{message.quoted_msg.sender_id === 'CRM' ? 'Manager' : 'Me'}, {formatMsgDisplayTime(message.quoted_msg.timestamp)}</div>
    </div>
    <div class="text">{message.text}</div>
</div>

<style>
    .quoted-svelte {
        display: flex;
        flex-direction: column;
        position: relative;
    }

    .citation {
        display: flex;
        flex-direction: column;
        padding-bottom: 5px;
        border-bottom: 1px solid black;
        font-size: 13px;
        padding-top: 5px;
        padding-left: 20px;
        margin-bottom: 10px;
    }

    .citation__time {
        margin-top: 3px;
    }
</style>
