<script lang="ts">
	import type { Message } from '../../../../types';

	export let message: Message;
</script>

<p class="text">{message.text}</p>

<style>
    .text {
        margin: 0;
    }
</style>
