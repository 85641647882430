export const checkToken = async (brand) => {
    const response = await fetch(document.location.origin + '/maintenance/check-token', {
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            brand,
        }),
    });
    return response;
};
