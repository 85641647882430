<script lang="ts">
	import type { Message, ResponseFileMessage } from '../../../../types';
	import { getResponseFileMessage } from '../index';
	import { createEventDispatcher } from 'svelte';
	import Slider from './slider/Slider.svelte';

	let statePopUp: boolean = false;
	export let message: Message;
	export let scrollWhenInitializingChat: Function;

	const fileParams: ResponseFileMessage = getResponseFileMessage(message);
	const imageUrl = fileParams.url;
	const imageName = fileParams.name;

	const dispatch = createEventDispatcher();

	const changeSliderState = () => {
		statePopUp = !statePopUp;
	}

	const hiddenMessage = () => {
		dispatch('error', {
			text: true,
		});
	}
</script>

<div class="text" on:click={changeSliderState} >
	<img src={imageUrl} class="image" alt={imageName} on:load={scrollWhenInitializingChat} on:error={hiddenMessage}/>
</div>

{#if statePopUp}
	<Slider {imageUrl} {changeSliderState}/>
{/if}

<style>
    .text {
        margin: 0;
        display: flex;
        flex-direction: row;
    }

    .image {
        width: 100%;
		height: 100%;
    }
</style>
