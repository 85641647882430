import { ResponseFileMessage } from '../../../types';
export const formatMsgDisplayTime = (milliseconds) => {
    if (milliseconds.length === 0)
        throw new Error('Empty string.');
    const date = new Date(Number(milliseconds));
    const hours = (date.getHours() < 10 ? '0' : '') + date.getHours();
    const minutes = (date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
    return `${hours}:${minutes}`;
};
// Deprecated
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const extractFileNameAndUrl = (str) => {
    try {
        if (str.length === 0)
            throw new Error('Empty string.');
        const matches = str.match('^name:{(.+)},(dataUrl|url):{(.+)}$');
        if (matches === null)
            throw new Error('Wrong string format.');
        if (matches.length === 4) {
            return {
                name: matches[1],
                url: matches[3],
            };
        }
        throw new Error('Wrong string format.');
    }
    catch (e) {
        console.error(e);
    }
};
export const inViewport = (element, parentElement) => {
    if (element instanceof HTMLElement) {
        return (parentElement.scrollTop + parentElement.clientHeight >= element.offsetTop);
    }
};
export const changeStatus = (msg) => {
    msg.status = 'read';
    return msg;
};
export const getResponseFileMessage = (msg) => {
    const resp = new ResponseFileMessage();
    try {
        resp.url = msg.fileUrl + msg.text;
        const splittedText = msg.text.split('_');
        if (splittedText.length < 3) {
            throw new Error('Splitted text is invalid. It has length less than 3');
        }
        resp.name = splittedText.slice(2).join('_');
        return resp;
    }
    catch (e) {
        console.error(e);
    }
    return resp;
};
