<script lang="ts">
	import { imagesMsgStore } from '../../../../../stores/imagesMsgStore';
	import { getUserImages } from '../../../../../infra/ChatAPI';
	import SvgDownload from '../../../../svg/SvgDownload.svelte';
	import Arrow from '../../../../svg/Arrow.svelte';
	import Close from '../../../../svg/Close.svelte';
	import { create_in_transition } from 'svelte/internal';
	import { getResponseFileMessage } from '../../index';
	import { onDestroy, onMount } from 'svelte';
	import { fly } from 'svelte/transition';

	export let changeSliderState: Function;
	export let imageUrl: string;

	let images = [];
	let color: string = '#b1b1b1';
	let height: string = '35';
	let width: string = '40';
	let imageElement: Element;

	const unsubscribeImagesMsgStore = imagesMsgStore.subscribe(array => images = [...array]);

	const initImagesStore = async () => {
		try {
			const data = await getUserImages();
			const messageImageUrl: Array<string> = data.messages.map(elem => elem.file_url + elem.text);
			imagesMsgStore.set(messageImageUrl);
		} catch (error) {
			console.log(error);
		}
	}

	const offScroll = () => {
		document.body.style.overflow = 'hidden';
	};

	const onScroll = () => {
		document.body.style.overflow = 'auto';
	};

	const processPreload = (images) => {
		images.forEach(url => {
			preload(url);
		})
	};

	const preload = (url) => {
		if (url == undefined) return;
		let img = new Image();
		img.src = url;
		img.onerror = () => {
			const index = images.indexOf(url);
			images = images.slice(0,index).concat(images.slice(index + 1));
			processPreload([images[index]]);
			processPreload([images[index-1]]);
			img = null;
		}
		img.onload = () => {
			img = null;
		}

	}

	const nextImage = () => {
		const newImageUrl = images[images.indexOf(imageUrl) + 1];
		if (images.indexOf(imageUrl) + 1 == images.length) {
			imageUrl = images[0];
		} else {
			imageUrl = newImageUrl;
		}
		processPreload([
			images[images.indexOf(imageUrl) - 2],
			images[images.indexOf(imageUrl) + 2],
		]);
		startAnimation(360);
	};

	const prevImage = () => {
		const newImageUrl = images[images.indexOf(imageUrl) - 1];
		if (images.indexOf(imageUrl) == 0) {
			imageUrl = images[images.length - 1];
		} else {
			imageUrl = newImageUrl;
		}
		processPreload([
			images[images.indexOf(imageUrl) - 2],
			images[images.indexOf(imageUrl) + 2],
		]);
		startAnimation(-360);
	};

	const startAnimation = (sideInPixels: number) => {
		const animationElement = imageElement as HTMLElement;
		const animationName = fly;
		const animationConf = { x: sideInPixels, duration: 450 };
		create_in_transition(animationElement, animationName, animationConf).start();
	};

	const download = () => {
		window.open(imageUrl);
	};

	onMount(() => {
		initImagesStore();
		offScroll();
	});

	onDestroy(() => {
		unsubscribeImagesMsgStore();
		onScroll();
	});
</script>

<div class="slider">
	<div class="slider__body">
		<div class="slider__body--navigation">
			<div class="icon-wrap" on:click={download}>
				<SvgDownload {width} {height} {color} />
			</div>
			<div class="icon-wrap close" on:click={changeSliderState}>
				<Close {width} {height} {color} />
			</div>
		</div>
		<div class="slider__body--image">
			{#if images.length > 1}
				<div class="slider__body--image-prev" on:click={prevImage}>
					<Arrow {width} {height} {color} />
				</div>
				<div class="slider__body--image-next" on:click={nextImage}>
					<Arrow {width} {height} {color} />
				</div>
			{/if}
			<img src={imageUrl} bind:this={imageElement} alt={imageUrl} />
		</div>
	</div>
</div>

<style>
    .slider {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 9999;
        -moz-user-select: -moz-none;
        -o-user-select: none;
        -khtml-user-select: none;
        -webkit-user-select: none;
        user-select: none;
    }

    .slider__body {
        position: fixed;
        background: rgba(0, 0, 0, .65);
        width: 100%;
        height: 100%;
    }

    .slider__body--navigation {
        position: fixed;
        top: 0;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 50px;
        justify-content: flex-end;
        align-items: center;
        z-index: 99999;
    }

    .icon-wrap {
        padding: 1px 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

    .icon-wrap:hover {
        filter: invert(99%) sepia(1%) saturate(2%) hue-rotate(54deg) brightness(370%) contrast(101%);
    }

    .slider__body--image {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        width: auto;
        padding: 2%;
        height: 94%;
    }

    .slider__body--image-prev,
    .slider__body--image-next {
        position: fixed;
        top: 50%;
        background-color: rgba(0, 0, 0, 0.5);
        width: 50px;
        height: 90px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: -90px;
        z-index: 999999;
        cursor: pointer;
    }

    .slider__body--image-prev:hover,
    .slider__body--image-next:hover {
        filter: invert(99%) sepia(1%) saturate(2%) hue-rotate(54deg) brightness(370%) contrast(101%);
    }

    .slider__body--image-prev {
        left: 0;
        transform: rotate(180deg);
    }

    .slider__body--image-next {
        right: 0;
    }

    .slider__body--image > img {
        max-width: 100%;
        width: auto;
        max-height: 100%;
    }
</style>
