import config from '../../config';
const { callSoundUrl, msgSoundUrl } = config;
const callNotification = new Audio(callSoundUrl);
const msgNotification = new Audio(msgSoundUrl);
export const startRinging = () => {
    callNotification.preload = 'auto';
    callNotification.loop = true;
    callNotification.play().catch();
};
export const stopRinging = () => {
    callNotification.pause();
    callNotification.currentTime = 0;
};
export const playNewMsg = () => {
    msgNotification.preload = 'auto';
    msgNotification.play().catch();
};
export const mute = (state) => {
    callNotification.muted = state;
    msgNotification.muted = state;
};
