<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
	 y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
    <path style="fill:#fff;" d="M448,0H64C28.704,0,0,28.704,0,64v288c0,35.296,28.704,64,64,64h32v80
    c0,6.208,3.584,11.872,9.216,14.496c2.144,0.992,4.48,1.504,6.784,1.504c3.68,0,7.328-1.28,10.24-3.712L232.992,416H448
    c35.296,0,64-28.704,64-64V64C512,28.704,483.296,0,448,0z" />
	<g>
        <path style="fill:#2196f3;"
			  d="M272,256H144c-8.832,0-16-7.168-16-16s7.168-16,16-16h128c8.832,0,16,7.168,16,16 S280.832,256,272,256z" />
		<path style="fill:#2196f3;"
			  d="M368,160H144c-8.832,0-16-7.168-16-16s7.168-16,16-16h224c8.832,0,16,7.168,16,16 S376.832,160,368,160z" />
    </g>
	<g></g>
	<g></g>
	<g></g>
	<g></g>
	<g></g>
	<g></g>
	<g></g>
	<g></g>
	<g></g>
	<g></g>
	<g></g>
	<g></g>
	<g></g>
	<g></g>
	<g></g>
</svg>

<style>
    svg {
        width: 50%;
    }
</style>