import App from './App.svelte';
const interfaceChat = (settings) => {
    new App({
        target: document.body,
        props: {
            lang: settings.lang,
            brand: settings.brand,
            style: settings.style,
        },
    });
};
window['ChatSvelte'] = interfaceChat;
