let peerConnectionCall;
let firstICECandidate = true;
let sendClientIceCandidate;
let sendClientAnswerMsg;
let localStream;
export const setSendClientIceCandidate = (f) => {
    sendClientIceCandidate = f;
};
export const setSendClientAnswerMsg = (f) => {
    sendClientAnswerMsg = f;
};
const constraints = {
    video: false,
    audio: { channelCount: 1 },
};
const errorHandler = error => {
    console.log(error);
};
const gotIceCandidate = event => {
    if (event.candidate) {
        if (firstICECandidate) {
            sendClientAnswerMsg(peerConnectionCall.localDescription.sdp).catch(errorHandler);
            firstICECandidate = false;
        }
        else {
            setTimeout(() => {
                sendClientIceCandidate(event.candidate.toJSON()).catch(errorHandler);
            }, 1000);
        }
    }
};
const getRemoteStream = event => {
    const remoteAudioStreamContainer = new Audio();
    remoteAudioStreamContainer.srcObject = event.streams[0];
    remoteAudioStreamContainer.play().catch(errorHandler);
};
const peerStateChangeCb = () => {
    console.log('Peer con state changed: ', peerConnectionCall.iceConnectionState);
    switch (peerConnectionCall.iceConnectionState) {
        case 'failed':
        case 'disconnected':
            hangUp();
            break;
        case 'connected':
            break;
        default:
            break;
    }
};
export const gotManagerOfferCb = args => {
    if (peerConnectionCall && peerConnectionCall.iceConnectionState !== 'closed') {
        return;
    }
    navigator.mediaDevices.getUserMedia(constraints).then(stream => {
        localStream = stream;
        peerConnectionCall = new RTCPeerConnection({ iceServers: [args.ice_server] });
        peerConnectionCall.oniceconnectionstatechange = peerStateChangeCb;
        peerConnectionCall.onicecandidate = gotIceCandidate;
        peerConnectionCall.ontrack = getRemoteStream;
        stream.getTracks().forEach(track => {
            peerConnectionCall.addTrack(track, stream);
        });
        return peerConnectionCall.setRemoteDescription(new RTCSessionDescription({
            sdp: args.sdp,
            type: 'offer',
        }));
    }).catch(errorHandler);
};
export const gotManagerICECandidate = data => {
    if (peerConnectionCall) {
        const iceCandidate = new RTCIceCandidate(data.args.ice);
        peerConnectionCall.addIceCandidate(iceCandidate).catch(errorHandler);
    }
};
export const createCallAnswer = () => {
    peerConnectionCall.createAnswer().then(answer => {
        return peerConnectionCall.setLocalDescription(answer);
    }).catch(errorHandler);
};
export const hangUp = () => {
    firstICECandidate = true;
    peerConnectionCall.close();
};
export const switcherMicrophone = (state) => {
    localStream.getAudioTracks()[0].enabled = state;
};
