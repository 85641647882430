<script lang="ts">
	import type { Message } from '../../../types';
	import MsgText from './msgText/MsgText.svelte';
	import MsgFile from './msgFile/MsgFile.svelte';
	import { afterUpdate, onDestroy } from 'svelte';
	import MsgImage from './msgImage/MsgImage.svelte';
	import MsgSystem from './msgSystem/MsgSystem.svelte';
	import Preloader from './preloader/Preloader.svelte';
	import HelloMessage from './helloMessage/HelloMessage.svelte';
	import { messagesStore } from '../../../stores/messagesStore';
	import { contextMenuStore } from '../../../stores/contextMenuStore';
	import { TypeMessages, webSocketStateType } from '../../../types';
	import ErrorDisconnected from './errorMessage/ErrorDisconnected.svelte';
	import ErrorUnauthorized from './errorMessage/ErrorUnauthorized.svelte';
	import { getChatHistory, msgChangeStatus } from '../../../infra/ChatAPI';
	import { changeStatus, formatMsgDisplayTime, inViewport } from './index';
	import { webSocketStateStore } from '../../../stores/webSocketStateStore';
	import { quoteStore } from '../../../stores/quoteStore';
	import { editMessageStore } from '../../../stores/editMessageStore';
	import { _ } from '../../../infra/services/i18n';
	import QuotedMessage from './quotedMessage/QuotedMessage.svelte';
	import EditMessage from './editMessage/EditMessage.svelte';
	import Edit from '../../svg/Edit.svelte';

	let messagesLength: number;
	let webSocketState: number;
	let visibilityQuote: boolean;
	let messages: Array<Message>;
	let chatBodyElement: Element;
	let firstUnreadMessage: number;
	let serverMessages: boolean = true;
	let messagesElements: Array<Element> = [];
	const managerName: string = 'CRM';

	const unsubscribeMessagesStore = messagesStore.subscribe(arr_msgs => messages = arr_msgs);
	const unsubscribeQuoteStore = quoteStore.subscribe(v => visibilityQuote = v.visibility);
	const unsubscribeWebSocketStateStore = webSocketStateStore.subscribe(v => webSocketState = v);

	afterUpdate(() => {
		scrollWhenInitializingChat();
		scrollAfterSendMessage();
		findLastReadMessage();
		if (chatBodyElement.scrollHeight === chatBodyElement.clientHeight) {
			checkStatusMessages();
		}
	});

	const onRightClick = (message) => {
		contextMenuStore.set(true);
		quoteStore.update(v => {
			v.message = message;
			return v;
		});
		editMessageStore.update(v => {
			v.contextMenu = false;
			v.visibility = false;
			v.messageId = message.msg_id;
			v.messageText = message.text;
			if (message.sender_id !== 'CRM' && (message.type === 0 || message.type === 4)) v.contextMenu = true;
			return v;
		});
	};

	const scrollWhenInitializingChat = () => {
		if (messages.length <= 10 || messagesLength == undefined) {
			chatBodyElement.scrollTop = chatBodyElement.scrollHeight;
		}
	};

	const scrollAfterSendMessage = () => {
		if (messagesLength + 1 == messages.length) {
			chatBodyElement.scrollTop = chatBodyElement.scrollHeight;
		}
		messagesLength = messages.length;
	};

	const loadHistoryOnScrollGetsTop = () => {
		if (webSocketState !== webSocketStateType.CONNECTED) return;
		if (chatBodyElement.scrollTop == 0 && serverMessages) {
			getChatHistory(messages[0].timestamp, 10).then(data => {
				const newestMessages = data.messages.length;
				const newestMsgElement = chatBodyElement.children[newestMessages - 1] as HTMLElement;
				if (newestMessages != 10) {
					serverMessages = false;
				}
				if (newestMessages == 0) return;
				data.messages.filter((item) => {
					if (item.sender_id !== 'CRM') {
						item.status = 'read';
					}
					return item;
				});
				messagesStore.update(oldMsgs => [...data.messages.reverse(), ...oldMsgs]);
				chatBodyElement.scrollTop = newestMsgElement.offsetTop;
				firstUnreadMessage += newestMessages;
			}).catch((error: Error) => {
				console.log(error);
			});
		}
	};

	const checkStatusMessages = () => {
		for (let i = 0; i < messagesElements.length; i++) {
			if (inViewport(messagesElements[i], chatBodyElement) && messages[i].status == 'unread' && messages[i].sender_id === managerName) {
				msgChangeStatus([{ msg_id: messages[i].msg_id, timestamp: messages[i].timestamp }]);
				changeStatus(messages[i]);
			}
		}
	};

	const findLastReadMessage = () => {
		for (const [index, message] of messages.entries()) {
			if ((message.status == 'read' || message.sender_id != managerName) && messages[index + 1] != undefined && messages[index + 1].status == 'unread') {
				if (firstUnreadMessage == undefined) {
					firstUnreadMessage = index + 1;
				}
				break;
			} else if (message.sender_id != managerName && index >= firstUnreadMessage && firstUnreadMessage != undefined) {
				firstUnreadMessage = undefined;
			}
		}
	};

	const handleError = (index) => {
		const newMessages = messages.slice(0, index).concat(messages.slice(index + 1));
		messagesStore.set(newMessages);
	};

	onDestroy(() => {
		unsubscribeMessagesStore();
		unsubscribeQuoteStore();
		unsubscribeWebSocketStateStore();
	});

</script>

<div class="body_svelte"
	 bind:this={chatBodyElement}
	 on:scroll={loadHistoryOnScrollGetsTop}
	 on:scroll={checkStatusMessages}
	 class:quoteVisibility={visibilityQuote}
>
	{#if webSocketState === webSocketStateType.DISCONNECTED}
		<ErrorDisconnected />
	{:else if webSocketState === webSocketStateType.UNAUTHORIZED}
		<ErrorUnauthorized />
	{/if}
	{#if webSocketState === undefined}
		<Preloader />
	{:else if webSocketState != undefined && messages.length == 0}
		<HelloMessage />
	{:else}
		{#each messages as message, i}
			{#if i === firstUnreadMessage}
				<div class="unread_massages">
					<div class="unread_massages_container">
						<hr>
						<p>Unread messages</p>
					</div>
				</div>
			{/if}
			{#if message.type != TypeMessages.SYSTEM && message.status !== 'delete'}
				<div class="message"
					 on:contextmenu|preventDefault={() => onRightClick(message)}
					 class:me="{managerName != message.sender_id}"
					 bind:this={messagesElements[i]}>
					<div class="container" class:margin="{i === firstUnreadMessage}">
						{#if message.status !== 'delete'}
							{#if message.type === TypeMessages.TEXT || message.type === TypeMessages.EDIT}
								{#if message.quoted_msg}
									<QuotedMessage {message} />
								{:else}
									<MsgText {message} />
								{/if}
							{:else if message.type === TypeMessages.FILE}
								<MsgFile {message}/>
							{:else if message.type === TypeMessages.IMAGE}
								<MsgImage {message} {scrollWhenInitializingChat} on:error={() => {handleError(i)}}/>
							{:else if message.type === TypeMessages.EDIT}
								<EditMessage {message}/>
							{/if}
						{/if}
						<p class="time">
							{formatMsgDisplayTime(message.timestamp)}{#if message.type === TypeMessages.EDIT}{`, ${$_('app.bodyComponent.edited')}`}{/if}
							{#if message.type === TypeMessages.EDIT}
								<span class="edit">
									<Edit />
								</span>
							{/if}
						</p>
					</div>
				</div>
			{:else}
				<div bind:this={messagesElements[i]} class="system_message">
					<MsgSystem {message}/>
				</div>
			{/if}
		{/each}
	{/if}
</div>

<style>
	.body_svelte {
		margin: 5px;
		background: white;
		border-radius: 5px;
		overflow-y: auto;
		height: 402px;
		position: relative;
		scrollbar-color: #a8a2a2 #dee3e9;
		scrollbar-width: thin;
	}

	.body_svelte.quoteVisibility {
		height: 364px;
	}

	::-webkit-scrollbar {
		width: 15px;
	}

	::-webkit-scrollbar-thumb {
		background-color: #FFF;
		border-radius: 15px;
		border: 4px solid #DEE2E9;
	}

	::-webkit-scrollbar-track {
		background: #DEE2E9;
	}

	.message {
		margin-right: 10%;
		justify-content: flex-start;
		padding: 0 .5em;
		margin-bottom: 15px;
		display: flex;
		align-items: center;
	}

	.message.me {
		margin-right: 0;
		margin-left: 10%;
		justify-content: flex-end;
	}

	.message > .edit {
		margin-left: 5px;
		margin-right: 0px;
	}

	.message.me > .edit {
		margin-left: 0px;
		margin-right: 5px;
	}

	.message > .container {
		border-radius: 5px 5px 0 0;
		padding: 10px 10px 3px 10px;
		min-height: 49px;
		color: #34363c;
		background: #f0f1f6;
		box-shadow: 1px 1px 1px 1px rgba(100, 100, 100, .3);
		word-break: break-word;
		font-size: 1rem;
		font-weight: 400;
		line-height: 1.5;
		height: auto;
	}

	.time {
		font-size: .7em;
		margin: 0;
	}

	.message .time {
		text-align: start;
	}

	.message.me .time {
		text-align: end;
	}

	.unread_massages {
		position: relative;
		margin: 0 .5em;
		padding: 0;
		height: 20px;
	}

	.unread_massages_container > hr {
		position: absolute;
		display: block;
		height: 1px;
		border: 0;
		border-top: 1px solid #6ea8d8;
		padding: 0;
		margin: 0;
		width: 100%;
	}

	.margin {
		margin-top: 1em;
	}

	.container {
		margin: 0;
	}

	.unread_massages_container > p {
		width: fit-content;
		position: absolute;
		margin-left: auto;
		margin-right: auto;
		left: 0;
		right: 0;
		top: -20px;
		text-align: center;
		background: #fff;
		font-size: 11px;
		padding: 0 2px;
		color: #6ea8d8;
	}

	@media screen and (max-width: 468px) {
		.body_svelte {
			height: 85%;
		}
		.message > .container {
			font-size: 20px;
		}

		.body_svelte.quoteVisibility {
			height: 79%;
		}
	}

	@media only screen and (max-width: 400px) {
		.body_svelte {
			height: 83%;
		}

		.body_svelte.quoteVisibility {
			height: 78%;
		}

	}

	@media only screen and (max-width: 375px) {
		.body_svelte {
			height: 82%;
		}

		.body_svelte.quoteVisibility {
			height: 76%;
		}
	}

	@media only screen and (max-height: 415px) {
		.body_svelte {
			height: 75%;
		}

		.body_svelte.quoteVisibility {
			height: 65%;
		}
	}

	@media only screen and (max-height: 400px) {
		.body_svelte {
			height: 72%;
		}

		.body_svelte.quoteVisibility {
			height: 62%;
		}
	}
</style>
