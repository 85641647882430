
<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 80 80" style="enable-background:new 0 0 80 80;" xml:space="preserve" width="80" height="80">
    <g>
        <path style="fill:#FFEEA3;" d="M3,73.5c-0.572,0-1.03-0.248-1.289-0.697c-0.293-0.508-0.266-1.186,0.071-1.77L38.434,7.249   C38.741,6.716,39.327,6.397,40,6.397s1.259,0.319,1.567,0.853l36.701,63.783c0.334,0.578,0.356,1.23,0.06,1.744   C78.062,73.236,77.578,73.5,77,73.5H3z"/>
        <path style="fill:#BA9B48;" d="M40,6.897c0.492,0,0.916,0.225,1.133,0.602l36.702,63.785c0.243,0.421,0.265,0.886,0.059,1.244   C77.718,72.832,77.4,73,77,73H3c-0.391,0-0.687-0.154-0.856-0.447c-0.204-0.354-0.177-0.841,0.071-1.272L38.866,7.5   C39.084,7.122,39.508,6.897,40,6.897 M40,5.897c-0.788,0-1.576,0.368-2,1.103L1.349,70.784C0.5,72.254,1.302,74,3,74h74   c1.698,0,2.55-1.746,1.701-3.216L42,7C41.575,6.265,40.788,5.897,40,5.897L40,5.897z"/>
    </g>
    <circle style="fill:#36404D;" cx="40" cy="60" r="3"/>
    <rect x="37" y="29" style="fill:#36404D;" width="6" height="24"/>
</svg>

<style>
    svg {
        width: 25px;
        height: 25px;
    }
</style>