<script lang="ts">
	import SvgError from '../../../svg/SvgError.svelte';
	import { _ } from '../../../../infra/services/i18n';
</script>

<div class="error_message">
	<SvgError />
	<p>
		{$_('app.bodyComponent.error_unauthorized')}
	</p>
</div>

<style>
    .error_message {
        width: 340px;
        height: 35px;
        background: rgba(100, 100, 100, .4);
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        position: fixed;
    }

    .error_message > p {
        margin-left: 5px;
        font-size: 14px;
    }

    @media screen and (max-width: 468px) {
        .error_message {
            width: 310px;
        }
    }
</style>