export default class Router {
    constructor(responseTimeoutSec, debug) {
        this._handlers = {};
        this._responseTimeoutSec = responseTimeoutSec;
        this._debug = debug;
    }
    register(cmd, handler, id = '') {
        if (this._debug)
            console.log(`register cmd: ${cmd} ${id}`);
        if (cmd.length === 0) {
            throw new Error('invalid cmd param');
        }
        const handlerName = id.length === 0 ? cmd : `${cmd}_${id}`;
        if (this._handlers[handlerName]) {
            if (this._debug)
                console.warn(`cmd ${handlerName} has been already registered`);
        }
        this._handlers[handlerName] = handler;
    }
    unregister(cmd, id = '') {
        if (this._debug)
            console.log(`unregister handler named ${cmd}`);
        if (cmd.length === 0) {
            if (this._debug)
                console.error('empty cmd');
            return;
        }
        const handlerName = id.length === 0 ? cmd : `${cmd}_${id}`;
        if (!this._handlers[handlerName]) {
            if (this._debug)
                console.error(`cmd ${handlerName} hasn't been registered`);
            return;
        }
        delete this._handlers[handlerName];
    }
    dataReceived(serializedData) {
        const data = JSON.parse(serializedData);
        if (data.cmd.length === 0) {
            if (this._debug)
                console.log('empty cmd has been received');
            return;
        }
        const { cmd } = data;
        const cb = this._handlers[cmd];
        if (!cb) {
            if (this._debug)
                console.log(`unexpected cmd has been received: ${cmd}`);
            return;
        }
        cb(data);
    }
    randomUUID() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
            const r = Math.random() * 16 | 0;
            const v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }
    setSendCb(cb) {
        this.send = cb;
    }
    setConnectionCheck(cb) {
        this.isConnected = cb;
    }
    registerHandler(cmd, handler) {
        this.register(cmd, handler);
    }
    sendRequest(params) {
        const { cmd, args, respCmd, withId } = params;
        if (!this.isConnected()) {
            return Promise.reject(new Error('Websocket is disconnected'));
        }
        const id = withId ? this.randomUUID() : '';
        const requestPromise = new Promise((resolve, reject) => {
            try {
                this.register(respCmd, (data) => {
                    if (data.error) {
                        reject(new Error(`cmd: ${data.cmd} response contains error: ${data.error}`));
                        this.unregister(respCmd, id);
                        return;
                    }
                    resolve(data.args);
                    this.unregister(respCmd, id);
                }, id);
                const outData = JSON.stringify({
                    id,
                    cmd,
                    args,
                });
                this.send(outData);
            }
            catch (e) {
                reject(new Error(`cmd "${cmd}" processing has been finished with error ${e}`));
                this.unregister(respCmd);
            }
        });
        const timeoutPromise = new Promise((resolve, reject) => {
            const timeout = this._responseTimeoutSec * 1000;
            setTimeout(() => {
                reject(new Error(`cmd "${cmd}" processing has been finished by timeout`));
            }, timeout);
        });
        return Promise.race([requestPromise, timeoutPromise]);
    }
}
