<div class="container">
	<svg xmlns:svg="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/2000/svg"
		 xmlns:xlink="http://www.w3.org/1999/xlink" version="1.0" width="64px" height="64px" viewBox="0 0 128 128"
		 xml:space="preserve">
        <rect x="0" y="0" width="100%" height="100%" fill="#FFFFFF" />
		<g>
                <path d="M40.73 13.1l-4.04-7A63.67 63.67 0 0 1 61.5.06v8a55.83 55.83 0 0 0-20.77 5.05z" fill="#000000"
					  fill-opacity="1" />
			<path d="M40.73 13.1l-4.04-7A63.67 63.67 0 0 1 61.5.06v8a55.83 55.83 0 0 0-20.77 5.05z" fill="#f8f8f8"
				  fill-opacity="0.03" transform="rotate(30 64 64)" />
			<path d="M40.73 13.1l-4.04-7A63.67 63.67 0 0 1 61.5.06v8a55.83 55.83 0 0 0-20.77 5.05z" fill="#e8e8e8"
				  fill-opacity="0.09" transform="rotate(60 64 64)" />
			<path d="M40.73 13.1l-4.04-7A63.67 63.67 0 0 1 61.5.06v8a55.83 55.83 0 0 0-20.77 5.05z" fill="#d4d4d4"
				  fill-opacity="0.17" transform="rotate(90 64 64)" />
			<path d="M40.73 13.1l-4.04-7A63.67 63.67 0 0 1 61.5.06v8a55.83 55.83 0 0 0-20.77 5.05z" fill="#bebebe"
				  fill-opacity="0.25" transform="rotate(120 64 64)" />
			<path d="M40.73 13.1l-4.04-7A63.67 63.67 0 0 1 61.5.06v8a55.83 55.83 0 0 0-20.77 5.05z" fill="#a6a6a6"
				  fill-opacity="0.35" transform="rotate(150 64 64)" />
			<path d="M40.73 13.1l-4.04-7A63.67 63.67 0 0 1 61.5.06v8a55.83 55.83 0 0 0-20.77 5.05z" fill="#8e8e8e"
				  fill-opacity="0.44" transform="rotate(180 64 64)" />
			<path d="M40.73 13.1l-4.04-7A63.67 63.67 0 0 1 61.5.06v8a55.83 55.83 0 0 0-20.77 5.05z" fill="#737373"
				  fill-opacity="0.55" transform="rotate(210 64 64)" />
			<path d="M40.73 13.1l-4.04-7A63.67 63.67 0 0 1 61.5.06v8a55.83 55.83 0 0 0-20.77 5.05z" fill="#5a5a5a"
				  fill-opacity="0.65" transform="rotate(240 64 64)" />
			<path d="M40.73 13.1l-4.04-7A63.67 63.67 0 0 1 61.5.06v8a55.83 55.83 0 0 0-20.77 5.05z" fill="#414141"
				  fill-opacity="0.75" transform="rotate(270 64 64)" />
			<path d="M40.73 13.1l-4.04-7A63.67 63.67 0 0 1 61.5.06v8a55.83 55.83 0 0 0-20.77 5.05z" fill="#2a2a2a"
				  fill-opacity="0.84" transform="rotate(300 64 64)" />
			<path d="M40.73 13.1l-4.04-7A63.67 63.67 0 0 1 61.5.06v8a55.83 55.83 0 0 0-20.77 5.05z" fill="#151515"
				  fill-opacity="0.92" transform="rotate(330 64 64)" />
			<animateTransform attributeName="transform" type="rotate"
							  values="0 64 64;30 64 64;60 64 64;90 64 64;120 64 64;150 64 64;180 64 64;210 64 64;240 64 64;270 64 64;300 64 64;330 64 64"
							  calcMode="discrete" dur="1080ms" repeatCount="indefinite"></animateTransform>
            </g>
        </svg>
</div>

<style>
    .container {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>
