export default {
    app: {
        headerComponent: {
            btn_answer_call: 'Antworten',
            btn_decline_call: 'Ablehnen',
            btn_stop_call: 'Halt',
        },
        bodyComponent: {
            error_disconnected: 'Keine Verbindung. Ich versuche erneut, eine Verbindung herzustellen.',
            error_unauthorized: 'Sitzung abgelaufen. Versuchen Sie es erneut.',
            edited: 'bearbeitet',
        },
        footerComponent: {
            btn_send: 'Senden',
            input_file: 'Datei',
            input_image: 'Bild',
            error_file_size: 'Dateien können nicht hochgeladen werden, da die Größe größer als die zulässige Größe ist - 5 MB:',
            confirmation_windows_close: 'Stornieren',
            confirmation_windows_send: 'Senden',
            quoted: 'Zitat:',
        },
        warningMicrophone: {
            title: 'Mikrofonzugriff verweigert',
            text: 'Bitte erlauben Sie den Zugang zum Mikrofon.<br/>Klicken Sie dazu in der Adressleiste des Browsers auf das Schlosssymbol am Anfang oder auf das Kamerasymbol am Ende der Zeile. Ermöglichen Sie im angezeigten Fenster den Zugriff auf das Mikrofon.',
            btn_close: 'Schließen',
        },
        systemMessage: {
            svc_call_started: 'Anruf',
            svc_call_ended: 'Anruf beendet',
            svc_call_rejected: 'Anruf abgelehnt',
            svc_call_missed: 'Verpasster Anruf',
            svc_switch_to: 'Gewechselt zu {name}',
            svc_delete_message: 'Nachricht gelöscht',
            svc_delete_history: 'Der Manager hat den Verlauf gelöscht',
        },
        fileMessage: {
            error_text: 'Die Datei ist beschädigt',
        },
        helloMessage: {
            title: 'Willkommen zurück',
            sub_title: 'Wie können wir Ihnen helfen?',
        },
        contextMenu: {
            quote: 'Zitieren',
            edit: 'Bearbeiten',
            cancel: 'Stornierung',
        },
    },
};
