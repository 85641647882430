<script lang="ts">
	import { contextMenuStore } from '../../../../stores/contextMenuStore';
	import { editMessageStore } from '../../../../stores/editMessageStore';
	import { _ } from '../../../../infra/services/i18n';
	import { fly } from 'svelte/transition';
	import { quoteStore } from '../../../../stores/quoteStore';

	let visibility;
	let visibilityEditItem;
	contextMenuStore.subscribe(v => visibility = v);
	editMessageStore.subscribe(v => visibilityEditItem = v.contextMenu);

	const onEditMessage = () => {
	    contextMenuStore.set(false);
	    editMessageStore.update(v => {
	        v.visibility = true;
	        return v;
	    });
	    quoteStore.update(state => {
	        state.visibility = false;
	        return state;
	    });
	};

	const onQuoteMessage = () => {
	    contextMenuStore.set(false);
	    quoteStore.update(state => {
	        state.visibility = true;
	        return state;
	    });
	    editMessageStore.update(v => {
	        v.visibility = false;
	        v.messageText = '';
	        return v;
	    });
	};

	const onCloseContextMenu = () => {
	    contextMenuStore.set(false);
	    editMessageStore.update(v => {
	        v.visibility = false;
	        v.messageText = '';
	        return v;
	    });
};
</script>

{#if visibility}
    <div class="context-menu-svelte">
        <div class="context-menu-svelte__items" transition:fly="{{ y: 200, duration: 200 }}">
            <div class="context-menu-svelte__items--line"></div>
            {#if visibilityEditItem}
            <p class="context-menu-svelte__items--edit items-cm-svelte" on:click={onEditMessage}>{$_('app.contextMenu.edit')}</p>
            {/if}
            <p class="context-menu-svelte__items--quote items-cm-svelte" on:click={onQuoteMessage}>{$_('app.contextMenu.quote')}</p>
            <p class="context-menu-svelte__items--cancel items-cm-svelte" on:click={onCloseContextMenu}>
                {$_('app.contextMenu.cancel')}
            </p>
        </div>
    </div>
{/if}

<style>
    .context-menu-svelte {
        width: 350px;
        height: 500px;
        background: rgba(0, 0, 0, 0.25);
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        position: fixed;
        border-radius: 5px;
        font-size: 15px;
        font-weight: 600;
        bottom: 20px;
    }

    .context-menu-svelte__items {
        background: #FFFFFF;
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        padding: 10px 0;
    }

    .context-menu-svelte__items--cancel {
        color: red;
    }

    .items-cm-svelte {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 10px 0;
        text-align: center;
    }

    .items-cm-svelte:hover {
        cursor: pointer;
        background: rgba(0, 0, 0, 0.13);
        transition: 300ms all linear;
    }

    .context-menu-svelte__items--line {
        width: 30px;
        height: 17px;
        background: #666;
        border-radius: 100px;
        margin-bottom: 15px;
        margin-top: 5px;
    }

    @media screen and (max-width: 468px) {
        .context-menu-svelte {
            width: 100%;
            height: 100%;
            bottom: 0;
        }
    }

    @media only screen and (max-height: 415px) {
        .context-menu-svelte {
            width: 100%;
            height: 100%;
            bottom: 0;
        }
    }
</style>
