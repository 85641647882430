<script lang="ts">
	import { soundNotificationsMuteStore } from '../../../stores/soundNotificationsMuteStore';
	import { callStateStore } from '../../../stores/callStateStore';
	import SvgSoundOff from '../../svg/SvgSoundOff.svelte';
	import SvgSoundOn from '../../svg/SvgSoundOn.svelte';
	import { stateChat } from '../../../stores/stateChat';
	import SvgClose from '../../svg/SvgClose.svelte';
	import { CallerStateType } from '../../../types';
	import { _ } from '../../../infra/services/i18n';
	import { onDestroy } from 'svelte';
	import { quoteStore } from '../../../stores/quoteStore';
	import { switcherMicrophone } from '../../../infra/Caller';
	import { microphoneStateStore } from '../../../stores/microphoneStateStore';
	import SvgVoice from '../../svg/SvgVoice.svelte';
	import SvgMute from '../../svg/SvgMute.svelte';
	import SvgStopCall from '../../svg/SvgStopCall.svelte';
	import SvgAnsverCall from '../../svg/SvgAnsverCall.svelte';

	let isSoundNotificationsMuted: boolean;
	let callState: number;
	let microphoneState: boolean;

	const unsubscribeSoundNotificationsMuteStore = soundNotificationsMuteStore.subscribe(v => isSoundNotificationsMuted = v);
	const unsubscribeCallStateStore = callStateStore.subscribe(v => callState = v);
	const unsubscribeMicrophoneStateStore = microphoneStateStore.subscribe(v => microphoneState = v);

	const changeState = () => {
		stateChat.update(v => !v);
		quoteStore.update(v => {
			v.visibility = false;
			return v;
		});
	};

	const toggleSoundNotificationsMute = () => {
		soundNotificationsMuteStore.set(!isSoundNotificationsMuted);
	};

	const answerCall = () => {
		callStateStore.set(CallerStateType.SPEAKING);
	};

	const stopCall = () => {
		callStateStore.set(CallerStateType.STOP_CALL);
		microphoneStateStore.set(true);
	};

	onDestroy(() => {
		unsubscribeSoundNotificationsMuteStore();
		unsubscribeMicrophoneStateStore();
		unsubscribeCallStateStore();
	});

</script>

<div class="header_svelte">
    <div class="caller">
        {#if callState === CallerStateType.SPEAKING}
            <div class="microphone-state" on:click={() => {
            	microphoneStateStore.set(!microphoneState);
				switcherMicrophone(microphoneState);
			}}>
                {#if microphoneState}
                    <SvgVoice/>
                {:else}
                    <SvgMute/>
                {/if}
            </div>
            <div id="stop" class="caller_btn" on:click={stopCall}>
                <SvgStopCall />
            </div>
        {:else if callState === CallerStateType.CONNECTING}
            <div id="answer" class="caller_btn" on:click={answerCall}>
                <SvgAnsverCall />
            </div>
            <div id="decline" class="caller_btn" on:click={stopCall}>
                <SvgStopCall />
            </div>
        {/if}
    </div>
    <div class="interface">
        <div class="notification" on:click={toggleSoundNotificationsMute}>
            {#if !isSoundNotificationsMuted}
                <SvgSoundOn/>
            {:else}
                <SvgSoundOff/>
            {/if}
        </div>
        <div class="close" on:click={changeState}>
            <SvgClose/>
        </div>
    </div>
</div>

<style>
    .microphone-state {
        height: 25px;
        width: 25px;
        margin: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .microphone-state:hover {
        transition: all 0.15s ease 0s;
        filter: brightness(125%);
    }

    .header_svelte {
        margin: 5px 5px 0 5px;
        border-radius: 5px;
        background: #19274a;
        height: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 20px;
    }

    .close {
        margin-right: 7px;
    }

    .caller {
        display: flex;
        flex-direction: row;
    }

    .caller_btn {
        height: 25px;
        width: 30px;
        margin: 5px 5px 5px 10px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .caller_btn:hover {
        transition: all 0.15s ease 0s;
        filter: brightness(125%);
    }

    .interface {
        display: flex;
        flex-direction: row;
    }

    .notification,
    .close {
        height: 30px;
        width: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

    .notification {
        margin-right: 7px;
    }

    .notification:hover,
    .close:hover {
        filter: invert(0%) sepia(95%) saturate(20%) hue-rotate(21deg) brightness(105%) contrast(106%);
        transition: all .3s cubic-bezier(.37, 0, .745, .715);
    }

    @media screen and (max-width: 400px) {
        .header_svelte {
            height: 8%;
        }
    }
</style>
