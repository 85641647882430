export class ResponseFileMessage {
}
export const CallerStateType = {
    STAND_BY: 0,
    CONNECTING: 1,
    SPEAKING: 2,
    STOP_CALL: 3,
    NO_MICROPHONE_ACCESS: 4,
};
export const webSocketStateType = {
    CONNECTED: 0,
    DISCONNECTED: 1,
    UNAUTHORIZED: 2,
};
export var TypeMessages;
(function (TypeMessages) {
    TypeMessages[TypeMessages["TEXT"] = 0] = "TEXT";
    TypeMessages[TypeMessages["FILE"] = 1] = "FILE";
    TypeMessages[TypeMessages["IMAGE"] = 2] = "IMAGE";
    TypeMessages[TypeMessages["SYSTEM"] = 3] = "SYSTEM";
    TypeMessages[TypeMessages["EDIT"] = 4] = "EDIT";
})(TypeMessages || (TypeMessages = {}));
export var TypeSystemMessage;
(function (TypeSystemMessage) {
    TypeSystemMessage[TypeSystemMessage["SVC_CALL_STARTED"] = 1] = "SVC_CALL_STARTED";
    TypeSystemMessage[TypeSystemMessage["SVC_CALL_ENDED"] = 2] = "SVC_CALL_ENDED";
    TypeSystemMessage[TypeSystemMessage["SVC_CALL_REJECTED"] = 3] = "SVC_CALL_REJECTED";
    TypeSystemMessage[TypeSystemMessage["SVC_CALL_MISSED"] = 4] = "SVC_CALL_MISSED";
    TypeSystemMessage[TypeSystemMessage["SVC_SWITCH_TO"] = 5] = "SVC_SWITCH_TO";
    TypeSystemMessage[TypeSystemMessage["SVC_DELETED_MESSAGE"] = 6] = "SVC_DELETED_MESSAGE";
    TypeSystemMessage[TypeSystemMessage["SVC_DELETED_HISTORY"] = 7] = "SVC_DELETED_HISTORY";
})(TypeSystemMessage || (TypeSystemMessage = {}));
