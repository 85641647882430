<script lang="ts">
	import { getResponseFileMessage} from '../index';
	import { onMount } from 'svelte';
	import SvgDownloadFile from '../../../svg/SvgDownloadFile.svelte';
	import SvgFileError from '../../../svg/SvgFileError.svelte';
	import { _ } from '../../../../infra/services/i18n';
	import type { Message, ResponseFileMessage } from "../../../../types";

	export let message: Message;

	const fileParams: ResponseFileMessage = getResponseFileMessage(message);
	let validFile: boolean = true;

	const checkFile = async () => {
		try {
			const response = await fetch(fileParams.url, { method: 'HEAD', mode: 'cors' });
			if (response.status != 200 || response.headers.get('content-length') == 0) {
				validFile = false;
			}
		} catch (e) {
			console.error(e)
		}
	};

	onMount(() => {
		//checkFile();
	});
</script>

<div class="text" title={fileParams.name}>
	{#if validFile}
		<div class="file">
			<SvgDownloadFile />
		</div>
		<iframe src="about:blank" name="iframe_a" style="display: none"></iframe>
		<a href="{fileParams.url}" target="iframe_a" download="{fileParams.name}" class="link">{(fileParams.name)}</a>
	{:else}
		<div class="file">
			<SvgFileError />
		</div>
		<p class="link error">{$_('app.fileMessage.error_text')}</p>
	{/if}
</div>

<style>
    .text {
        margin: 0;
        display: flex;
        flex-direction: row;
		justify-content: center;
		align-items: center;
    }

    .file {
		display: flex;
		justify-content: center;
		align-items: center;
        margin-right: 5px;
    }

    .link {
        text-decoration: none;
        font-size: 16px;
        margin-top: 2px;
        color: #19274A;
        max-width: 240px;
    }

	.link.error {
		margin: 0;
	}
</style>
