let wsRouter;
export const setRouter = (r) => {
    wsRouter = r;
};
export const getChatHistory = (from, count) => wsRouter.sendRequest({
    cmd: 'client_chat_query',
    args: {
        from, count,
    },
    respCmd: 'chat_history',
});
export const sendClientMsg = (text, type, quoted_msg_id) => wsRouter.sendRequest({
    cmd: 'client_msg',
    args: {
        text, type, quoted_msg_id,
    },
    respCmd: 'client_msg_response',
});
export const sendClientEditMsg = (text, msg_id) => wsRouter.sendRequest({
    cmd: 'client_edited_msg',
    args: {
        text, msg_id,
    },
    respCmd: 'client_edited_msg_response',
});
export const sendClientOfferDecline = () => wsRouter.sendRequest({
    cmd: 'client_offer_decline',
    args: {},
    respCmd: 'client_offer_decline_response',
});
export const sendClientStopCall = () => wsRouter.sendRequest({
    cmd: 'client_stop_call',
    args: {},
    respCmd: 'client_stop_call_response',
});
export const sendClientAnswerMsg = (sdp) => wsRouter.sendRequest({
    cmd: 'client_answer',
    args: {
        sdp,
    },
    respCmd: 'client_answer_response',
});
export const sendClientIceCandidate = (ice) => wsRouter.sendRequest({
    cmd: 'client_ice',
    args: {
        ice,
    },
    respCmd: 'client_ice_response',
});
export const msgChangeStatus = (messages) => wsRouter.sendRequest({
    cmd: 'client_msg_read',
    args: {
        messages,
    },
    respCmd: 'client_msg_read_response',
});
export const getUserImages = () => wsRouter.sendRequest({
    cmd: 'get_user_images',
    args: {},
    respCmd: 'get_user_images_response',
});
export const setUserFireBaseToken = (token) => wsRouter.sendRequest({
    cmd: 'set_user_firebase_token',
    args: token,
    respCmd: 'set_user_firebase_token_response',
});
export const msgReceived = (callback) => {
    wsRouter.register('manager_msg', callback);
};
export const msgClient = (callback) => {
    wsRouter.register('client_msg', callback);
};
export const managerEditedMsg = (callback) => {
    wsRouter.register('manager_edited_msg', callback);
};
export const managerOffer = (callback) => {
    wsRouter.register('manager_offer', callback);
};
export const managerIce = (callback) => {
    wsRouter.register('manager_ice', callback);
};
export const managerStopCall = (callback) => {
    wsRouter.register('manager_stop_call', callback);
};
export const resetStateOtherWindows = (callback) => {
    wsRouter.register('reset_state', callback);
};
export const managerMsgsMarkedDeletion = (callback) => {
    wsRouter.register('manager_msgs_marked_deletion', callback);
};
export const managerDeleteHistoryMessages = (callback) => {
    wsRouter.register('manager_marked_deletion_from_msg_id', callback);
};
