export default {
    app: {
        headerComponent: {
            btn_answer_call: 'Answer',
            btn_decline_call: 'Decline',
            btn_stop_call: 'Stop',
        },
        bodyComponent: {
            error_disconnected: 'No connection. Trying reconnect.',
            error_unauthorized: 'Session expired. Try relogin.',
            edited: 'edited',
        },
        footerComponent: {
            btn_send: 'Send',
            input_file: 'File',
            input_image: 'Image',
            error_file_size: 'Files cannot be uploaded because the size is larger than the allowed size - 5 MB:',
            confirmation_windows_close: 'Cancel',
            confirmation_windows_send: 'Send',
            quoted: 'Сitation:',
        },
        warningMicrophone: {
            title: 'Microphone access denied',
            text: 'Please allow access to the microphone.<br/>To do this, in the address bar of the browser, click on the lock icon at its beginning or on the camera icon at the end of the line. In the window that appears, allow access to the microphone.',
            btn_close: 'Close',
        },
        systemMessage: {
            svc_call_started: 'Call started',
            svc_call_ended: 'Call ended',
            svc_call_rejected: 'Call rejected',
            svc_call_missed: 'Missed call',
            svc_switch_to: 'Switched to {name}',
            svc_delete_message: 'Message deleted',
            svc_delete_history: 'Manager cleared history',
        },
        fileMessage: {
            error_text: 'The file is damaged',
        },
        helloMessage: {
            title: 'Welcome',
            sub_title: 'How can we help you?',
        },
        contextMenu: {
            quote: 'Quote',
            edit: 'Edit',
            cancel: 'Cancel',
        },
    },
};
