<script lang="ts">
	import { callStateStore } from '../../stores/callStateStore';
	import { CallerStateType } from '../../types';
	export let warning;

	let callStateChat: number;
	callStateStore.subscribe(v => callStateChat = v);

	const closePopUp = () => {
		callStateStore.set(CallerStateType.STAND_BY);
	};
</script>

<div class="no-access_svelte">
	<div class="background_svelte" on:click={closePopUp}></div>
	<div class="popUp_no-access">
		<div class="popUp_no-access--close" on:click={closePopUp}>&#10005;</div>
		<h3 class="popUp_no-access--title">
			{warning.title}
		</h3>
		<div class="popUp_no-access--text">
			{@html warning.text}
		</div>
		<div class="popUp_btn--close" on:click={closePopUp}>
			{warning.btn_close}
		</div>
	</div>
</div>

<style>
    .no-access_svelte {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        position: fixed;
        z-index: 9999999999;
    }

    .background_svelte {
        position: fixed;
        background: rgba(0, 0, 0, .65);
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
    }

    .popUp_no-access {
        width: 360px;
        background: white;
        z-index: 999999999;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 20px;
        text-align: center;
        position: relative;
    }

    .popUp_no-access--close {
        position: absolute;
        color: black;
        top: 5px;
        right: 10px;
        cursor: pointer;
    }

    .popUp_no-access--title {
        color: red;
        font-size: 20px;
        margin: 0;
        margin-top: 10px;
    }

	 .popUp_no-access--text {
        color: black;
        font-size: 14px;
		 margin-top: 10px;
    }

    .popUp_btn--close {
        background: #1fcd46;
        padding: 6px;
        border-radius: 2px;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        font-size: 14px;
        cursor: pointer;
        margin-top: 10px;
    }

    .popUp_btn--close:hover {
        background: #22bd45;
        transition: all .3s cubic-bezier(.47, 0, .745, .715);
    }

    @media screen and (max-width: 468px) {
        .popUp_no-access {
            max-width: 320px;
            margin: 3px;
        }
    }
</style>