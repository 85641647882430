<svg viewBox="0 0 24 24" class="file_svg_svelte">
    <path fill="#fff" d="M14,2L20,8V20A2,2 0 0,1 18,22H6A2,2 0 0,1 4,20V4A2,2 0 0,1 6,2H14M18,20V9H13V4H6V20H18M12"></path>
</svg>

<style>
    .file_svg_svelte {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: #40a7e2;
        padding: 7px;
    }
</style>
