<script>
	export let size = '1em';
	export let width = size;
	export let height = size;
	export let color = 'currentColor';
	export let viewBox = '0 0 24 24';
</script>

<svg width="{width}" height="{height}" viewBox="{viewBox}">
	<path d="M5,20H19V18H5M19,9H15V3H9V9H5L12,16L19,9Z" fill="{color}"/>
</svg>