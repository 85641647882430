<script lang="ts">
	import type { Message } from '../../../../types';
	import Edit from '../../../svg/Edit.svelte';

	export let message: Message;
</script>

<div class="text">
    <div class="edit">
        <Edit />
    </div>
    {message.text}
</div>

<style>
    .text {
        margin: 0;
    }
</style>
