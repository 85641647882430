<script lang="ts">
	import Header from './header/Header.svelte';
	import Body from './body/Body.svelte';
	import Footer from './footer/Footer.svelte';
	import { fly } from 'svelte/transition';
	import ContextMenu from './body/contextMenu/ContextMenu.svelte';

	export let style: Object;
</script>

<div class="body_svelte {style.position} {style.theme}" transition:fly="{{ y: 200, duration: 350 }}">
	<Header />
	<Body />
	<Footer theme={style.theme}/>
	<ContextMenu />
</div>

<style>
    .body_svelte {
        max-width: 350px;
        width: 100%;
        height: 500px;
        box-shadow: 0 10px 20px -5px #000;
        border-radius: 5px;
        position: fixed;
		z-index: 99999999;
        background-color: white;
    }

    .body_svelte.left {
        bottom: 20px;
        left: 20px;
	}

    .body_svelte.right {
        bottom: 20px;
        right: 20px;
	}

	.body_svelte.light {
        background-color: #ECEEF2;
	}

    .body_svelte.dark {
        background-color: #19274a;
    }

    @media screen and (max-width: 468px) {
        .body_svelte {
            max-width: 100%;
			height: 100%;
            border-radius: 0;
        }

        .body_svelte.left {
            bottom: 0;
            left: 0;
        }

        .body_svelte.right {
            bottom: 0;
            right: 0;
        }
    }

    @media only screen and (max-height: 415px) {
        .body_svelte {
            max-width: 100%;
            height: 100%;
            border-radius: 0;
        }

        .body_svelte.left {
            bottom: 0;
            left: 0;
        }

        .body_svelte.right {
            bottom: 0;
            right: 0;
        }
	}
</style>
