<script lang="ts">
    import { _ } from '../../../../infra/services/i18n';
</script>

<div class="hello-message">
	<div class="hello-message__block">
		<h2 class="hello-message__block--title">{$_('app.helloMessage.title')}</h2>
		<p class="hello-message__block--subtitle">{$_('app.helloMessage.sub_title')}</p>
	</div>
</div>

<style>
	.hello-message {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;
		color: #c4c9d4;
		text-align: center;
        -moz-user-select: -moz-none;
        -o-user-select: none;
        -khtml-user-select: none;
        -webkit-user-select: none;
        user-select: none;
	}
	.hello-message__block {
		width: 90%;
		height: fit-content;
		margin: 0 auto;
	}

    .hello-message__block--title,
    .hello-message__block--subtitle {
		margin: 0;
		padding: 0;
		font-weight: normal;
	}

    .hello-message__block--subtitle {
		margin-top: 10px;
	}
</style>