export default class ConnManager {
    constructor(params) {
        const { pingMsg, pingTimeoutSec, reconnectAfterSec } = params;
        this.pingMsg = pingMsg;
        this.pingTimeoutSec = pingTimeoutSec;
        this.reconnectAfterSec = reconnectAfterSec;
        this.disconnectCb = () => {
        };
        this.connectCb = () => {
        };
        this.sendCb = () => {
        };
        this.startPing();
    }
    startPing() {
        setInterval(() => {
            this.sendCb(this.pingMsg);
        }, this.pingTimeoutSec * 1000);
    }
    stopReconnect() {
        clearTimeout(this.reconnectTimer);
    }
    disconnected(auth) {
        this.sendCb = () => { };
        auth.then((response) => {
            if (!response.ok)
                return;
            this.reconnectTimer = setTimeout(() => {
                this.connectCb();
            }, 10000);
        }).catch();
    }
    setConnectCb(cb) {
        this.connectCb = cb;
    }
    setSendCb(cb) {
        this.sendCb = cb;
    }
}
