<script lang="ts">
	import { _ } from '../../../../infra/services/i18n';
	import type { Message } from '../../../../types';
	import { formatMsgDisplayTime } from '../index';
	import { TypeSystemMessage } from '../../../../types';

	export let message: Message;
	let typeMsg = Number(message.text.split(';')[0]);
	let text: string;

	switch (typeMsg) {
		case TypeSystemMessage.SVC_CALL_STARTED:
			text = $_('app.systemMessage.svc_call_started');
			break;
		case TypeSystemMessage.SVC_CALL_ENDED:
			text = $_('app.systemMessage.svc_call_ended');
			break;
		case TypeSystemMessage.SVC_CALL_REJECTED:
			text = $_('app.systemMessage.svc_call_rejected');
			break;
		case TypeSystemMessage.SVC_CALL_MISSED:
			text = $_('app.systemMessage.svc_call_missed');
			break;
        case TypeSystemMessage.SVC_DELETED_MESSAGE:
        	text = $_('app.systemMessage.svc_delete_message');
        	break;
			case TypeSystemMessage.SVC_DELETED_HISTORY:
        	text = $_('app.systemMessage.svc_delete_history');
        	break;
		case TypeSystemMessage.SVC_SWITCH_TO:
			text = $_('app.systemMessage.svc_switch_to', {values: {name: message.text.split(';')[1]}});
			break;
		default: text = message.text; break;
	}
</script>

<p class="text">{message.timestamp === 'none' ? '' : formatMsgDisplayTime(message.timestamp)} {text}</p>

<style>
    .text {
        text-align: center;
		padding: 10px;
        font-size: 13px;
        color: gray;
		margin: 0;
		word-break: break-word;
    }
</style>
