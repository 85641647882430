import { dictionary, locale, _ } from 'svelte-i18n';
import en from '../../lang/en';
import ru from '../../lang/ru';
import pl from '../../lang/pl';
import de from '../../lang/de';
const setupI18n = (lang) => {
    dictionary.set({
        en: en,
        ru: ru,
        pl: pl,
        de: de,
    });
    locale.set(lang);
};
export { _, setupI18n };
