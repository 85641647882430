<script lang="ts">
	import FilesInputs from './fileInputs/FileInputs.svelte';
	import { messagesStore } from '../../../stores/messagesStore';
	import { sendClientMsg, sendClientEditMsg } from '../../../infra/ChatAPI';
	import type { Message } from '../../../types';
	import { webSocketStateType } from '../../../types';
	import { webSocketStateStore } from '../../../stores/webSocketStateStore';
	import { onDestroy, afterUpdate } from 'svelte';
	import { checkMsgForEmptiness } from './index';
	import { _ } from '../../../infra/services/i18n';
	import { quoteStore } from '../../../stores/quoteStore';
	import { editMessageStore } from '../../../stores/editMessageStore';
	import Send from '../../svg/Send.svelte';
	import SvgClose from '../../svg/SvgClose.svelte';

	export let theme: string;

	let webSocketState: number;
	let messageQuote: Message;
	let visibility: boolean;
	let visibilityEditMessage: boolean;
	let editTextMessage: string;
	let editMessage: boolean;
	let value: string = '';
	let state: boolean = false;
	let userId: string;
	let editMsgID: number;

	const unsubscribeWebSocketStateStore = webSocketStateStore.subscribe(v => webSocketState = v);
	const unsubscribeQuoteStore = quoteStore.subscribe(v => {
		visibility = v.visibility;
		messageQuote = v.message;
	});
	const unsubscribeEditMessageStore = editMessageStore.subscribe(v => {
		visibilityEditMessage = v.visibility;
		editMessage = v.edited;
		if (!v.visibility) {
			value = v.messageText;
			editTextMessage = v.messageText;
			editMsgID = v.messageId;
		}
	});

	const processMsg = async () => {
		if (webSocketState !== webSocketStateType.CONNECTED) return;
		try {
			checkMsgForEmptiness(value);
			const arrayMsg = value.match(/.{1,5000}/g);
			for (const msg of arrayMsg) {
				if (visibility) {
					await sendMsg(msg, messageQuote.msg_id).then((message: Message) => {
						messagesStore.update(oldMsgs => [...oldMsgs, message]);
						cancelQuoteMessage();
					}).catch((e: Error) => {
						console.log(e);
					});
				} else {
					await sendMsg(msg).then((message: Message) => {
						messagesStore.update(oldMsgs => [...oldMsgs, message]);
					}).catch((e: Error) => {
						console.log(e);
					});
				}
			}
			value = '';
		} catch (error) {
			console.log(error);
		}
	};

	const processEditMsg = async () => {
		if (webSocketState !== webSocketStateType.CONNECTED) return;
		if (visibilityEditMessage && !editMessage) return;
		try {
			checkMsgForEmptiness(value);
			await sendEditMsg(value, editMsgID).then((message) => {
				messagesStore.update((allMsgs) => {
					let msg = allMsgs.find(msg => msg.msg_id == editMsgID);
					msg.type = message.type;
					msg.text = message.text;
					return [...allMsgs];
				});
				editMessageStore.update(v => {
					v.visibility = false;
					v.edited = false;
					return v;
				});
			}).catch((e: Error) => {
				console.log(e);
			});
			value = '';
		} catch (error: Error) {
			console.log(error);
		}
	};

	const sendMsg = (msg: string, quoted_msg_id: any = null) => {
		return sendClientMsg(msg, 0, Number(quoted_msg_id));
	};

	const sendEditMsg = (text: string, msgID: number) => {
		return sendClientEditMsg(text, msgID);
	};

	const changeStatePopUp = () => {
		state = !state;
	};

	const onKeyPress = e => {
		if (e.charCode === 13) {
			e.preventDefault();
			if (visibilityEditMessage) {
				processEditMsg();
			} else {
				processMsg();
			}
		}
	};

	const cancelQuoteMessage = () => {
		quoteStore.update(v => {
			v.visibility = false;
			return v;
		});
	};

	const cancelEditMessage = () => {
		editMessageStore.update(v => {
			v.visibility = false;
			v.edited = false;
			return v;
		});
		value = "";
	};

	const changeStateEditMessage = (edited) => {
		editMessageStore.update(v => {
			v.edited = edited;
			return v;
		});
	};

	afterUpdate(() => {
		if (visibilityEditMessage) {
			value == editTextMessage ? changeStateEditMessage(false) : changeStateEditMessage(true);
			if(value.length === 0) changeStateEditMessage(false);
		}
	});

	onDestroy(() => {
		unsubscribeWebSocketStateStore();
		unsubscribeQuoteStore();
		unsubscribeEditMessageStore();
	});

</script>

{#if visibility}
	<div class="footer_svelte_quote">
		<div class="footer_svelte_quote__line"></div>
		<div class="footer_svelte_quote__text">
			<div class="footer_svelte_quote__text--first">{$_('app.footerComponent.quoted')}</div>
			<div class="footer_svelte_quote__text--second" title={messageQuote.text}>{messageQuote.text}</div>
		</div>
		<div class="footer_svelte_quote__cancel" on:click={cancelQuoteMessage}>
			<svg viewBox="0 0 30 30" fill="black" xmlns="http://www.w3.org/2000/svg" id="Capa_1" class="svg_close">
				<path d="M7 7L23 23" stroke="black" stroke-width="2"/>
				<path d="M23 7L7 23" stroke="black" stroke-width="2"/>
			</svg>

		</div>
	</div>
{/if}
<div class="footer_svelte">
	{#if !visibilityEditMessage}
	<div class="files" on:click={changeStatePopUp}></div>
	{/if}
	{#if state}
		<FilesInputs {changeStatePopUp} />
	{/if}
	<textarea bind:value on:keypress={onKeyPress} class="textarea" class:edit="{visibilityEditMessage}"></textarea>
	<div class="send {theme}" on:click={() => {
		if (visibilityEditMessage && !editMessage) {
			cancelEditMessage();
			return;
		}
		visibilityEditMessage ? processEditMsg() : processMsg();
	}} class:disabled="{webSocketState !== webSocketStateType.CONNECTED}" class:edit="{visibilityEditMessage && !editMessage}">
		{#if visibilityEditMessage && !editMessage}
			<div class="edit_svg">
				<SvgClose />
			</div>
		{:else }
			<Send />
		{/if}
	</div>
</div>

<style>
	.footer_svelte {
		display: flex;
		flex-direction: row;
		justify-content: center;
		height: 38px;
		margin: 0 5px 5px 5px;
		width: 339px;
		position: relative;
		padding-top: 0;
	}

	.footer_svelte_quote {
		display: flex;
		flex-direction: row;
		align-items: center;
		height: 38px;
		margin: 0 5px;
		width: 265px;
		position: relative;
		padding-top: 0;
		padding-left: 20px;
	}

	.footer_svelte_quote__line {
		height: 26px;
		width: 2px;
		background: #6ea8d8;
		margin-right: 5px;
	}

	.footer_svelte_quote__text {
		display: flex;
		flex-direction: column;
		font-size: 12px;
	}

	.footer_svelte_quote__text--first {
		color: #6ea8d8;
	}

	.footer_svelte_quote__text--second {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		text-align: start;
		width: 245px;
	}

	.footer_svelte_quote__cancel {
		width: 15px;
		height: 15px;
		position: absolute;
		right: 0;
		top: 6px;
		cursor: pointer;
	}

	.textarea {
		overflow-y: auto;
		resize: none;
		height: auto;
		display: flex;
		flex: 1;
		padding: .375rem .75rem .375rem 30px;
		font-size: 16px;
		font-weight: 400;
		line-height: 1.5;
		color: #495057;
		background-color: #fff;
		background-clip: padding-box;
		border: 1px solid #ced4da;
		border-top-left-radius: .25rem;
		border-bottom-left-radius: .25rem;
		transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	}

	.textarea.edit {
		padding: .375rem .75rem;
	}

	.textarea:focus {
		border-color: #6da8d8;
	}

	.send {
		background: #4a9d45;
		color: #fff;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		border-radius: 50%;
		margin-left: 5px;
		width: 38px;
		height: 38px;
	}

	.send.light {
		background: #4a9d45;
	}

	.send.dark {
		background: #6ea8d8;
	}

	.send.disabled {
		background: #c1c1c1;
		cursor: not-allowed;
	}

	.send.edit {
		background: #a2a0a0;
	}

	.send.edit:hover {
		background: #8c8a8a !important;
	}

	.send.edit > .edit_svg {
		filter: invert(100%) sepia(24%) saturate(41%) hue-rotate(88deg) brightness(283%) contrast(132%);
	}

	.send.light:hover {
		background: #52944e;
		transition: all .3s cubic-bezier(.47,0,.745,.715);
	}

	.send.dark:hover {
		background: #6992b5;
		transition: all .3s cubic-bezier(.47,0,.745,.715);
	}

	.files {
		position: absolute;
		left: 0;
		background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAOxAAADsQBlSsOGwAAAi9JREFUaIHtmr1OG0EUhT8iBG5cUNBBEVeWwA2u3EHlR0hjmnRpIh6BF8AP4S55ASSKpKKigQIqS0i0qRBRbAV5k2Luau8sXqxdj32NmCONNLo/M+fM3tmVZhbmxxrQBQbAEBhLG4qtKzEriSZwCSTAv4KWSEzTiGMhDoFHionn26PkrASa+ORHQB9oAzVpbbGN8EWYP4k1XEmkpB6A1ivxLYlJ4y8x3hNdspof8Tr5FC2yJ5HIGGYYkK1mv0ReX+UNcr5EtYVjqIi0S+S1yZ7cMOfTb7GFY6wmq5XIq5ERHed8SxUwz2Rpbr5UKo/5oQKJEAj2FrISEAzrAcfSZbG0hQkpAAw+UG++hKIAa0QB1ogCrBEFWCMKsEYUYI0owBpRgDWiAGtEAdZ49wI2VF+fd25NidW2UcEYpVFFwLPq76j+reofT8k7Jjs30rF6jL8V+JTGNdlBbE/ZT/CvnL7iVn1L+vqq6UTl9ZT9esHcAThTE54r+yZwo3zpKXT+BvMG/1j+XPnKXJhUxoEiNQE6yrfDSxF58rsqviNjpGLLXJjMhQtF6g6oK98mrkSugD/SrsSmV76O2wvpOBcLZ62wh1/TP/BFzEJdcvSe2Q/McSa+4Nf3HX45FaGDv/KJjGWCU3wRE9ym7AEN3Dt+Q/o98U3wyZ8umfMLfAaemP6PhG55/2/JXQk0gO+4j9ysfyaeJbYRYuLQNyofgU/AEW6jb4v9F67ufwLfgPtQE/4HpXrT4cLYC9sAAAAASUVORK5CYII=');
		background-repeat: no-repeat;
		background-position: center;
		background-size: contain;
		width: 25px;
		height: 20px;
		margin-left: 3px;
		filter: invert(10%) sepia(64%) saturate(1276%) hue-rotate(199deg) brightness(98%) contrast(92%);
		cursor: pointer;
		align-self: center;
	}

	@media screen and (max-width: 468px) {
		.footer_svelte {
			width: 97%;
			height: 7%;
		}
	}

	@media only screen and (max-height: 415px) {
		.footer_svelte {
			width: 98%;
			height: 11%;
		}
	}
</style>
