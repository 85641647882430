<script lang="ts">
	import { soundNotificationsMuteStore } from './stores/soundNotificationsMuteStore';
	import { webSocketStateStore } from './stores/webSocketStateStore';
	import * as soundNotification from './infra/SoundNotification';
	import { CallerStateType, webSocketStateType } from './types';
	import IconChat from './components/iconChat/IconChat.svelte';
	import { callStateStore } from './stores/callStateStore';
	import { messagesStore } from './stores/messagesStore';
	import ConnManager from './infra/WsRpc/connManager';
	import Chat from './components/chat/Chat.svelte';
	import { stateChat } from './stores/stateChat';
	import { setupI18n } from './infra/services/i18n';
	import { checkToken } from './infra/Requests';
	import { _ } from './infra/services/i18n';
	import * as chatAPI from './infra/ChatAPI';
	import Router from './infra/WsRpc/router';
	import * as caller from './infra/Caller';
	import WsRpc from './infra/WsRpc';
	import Warning from './components/modalWindow/Warning.svelte';
	import config from './config';
	import { microphoneStateStore } from './stores/microphoneStateStore';
	import { managerDeleteHistoryMessages } from './infra/ChatAPI';

	export let lang: string;
	export let brand: string;
	export let style: Object;

	let warning: Object;

	let stateChatUI: boolean;
	let callStateChat: number;
	stateChat.subscribe(v => stateChatUI = v);

	const connManagerParams = {
		pingMsg: '.',
		pingTimeoutSec: 8,
		reconnectAfterSec: 10,
	};

	callStateStore.subscribe(callState => {
		callStateChat = callState;
		switch (callState) {
			case CallerStateType.SPEAKING:
				caller.createCallAnswer();
				soundNotification.stopRinging();
				break;
			case CallerStateType.STOP_CALL:
				caller.hangUp();
				soundNotification.stopRinging();
				chatAPI.sendClientStopCall();
				callStateStore.set(CallerStateType.STAND_BY);
		}
	});

	soundNotificationsMuteStore.subscribe(state => {
		soundNotification.mute(state);
	});

	const wsConnManager = new ConnManager(connManagerParams);
	const wsRouter = new Router(10, true);
	chatAPI.setRouter(wsRouter);

	let domain;
	const domains = document.location.hostname.split('.');
	switch (domains.length) {
		case 2:
			domain = document.location.hostname;
			break;
		case 3:
			domain = domains.slice(1).join('.');
			break;
	}

	const wsRpcParams = {
		url: `wss://${config.subDomain}.${domain}/api/ws/v2?lang=${lang}&brand=${brand}`,
		responseRouter: wsRouter,
		wsReConnector: wsConnManager,
	};

	const wsRpc = new WsRpc(wsRpcParams);
	wsRpc.connect();
	wsRpc.onConnected(() => {
		webSocketStateStore.set(webSocketStateType.CONNECTED);
		chatAPI.getChatHistory(0, 10).then(data => {
			const { messages: m } = data as chatAPI.ChatHistory;
			m.filter((item) => {
				if (item.sender_id !== 'CRM') {
					item.status = 'read';
				}
				return item;
			});
			return messagesStore.set(m.reverse());
		}).catch((error) => {
			console.log(error);
		});
	});

	wsRpc.onDisconnected(() => {
		webSocketStateStore.set(webSocketStateType.DISCONNECTED);
		return checkToken(brand);
	});

	wsRpc.onUnauthorized(() => {
		webSocketStateStore.set(webSocketStateType.UNAUTHORIZED);
	});

	chatAPI.msgReceived(data => {
		data.args.sender_id = 'CRM';
		messagesStore.update(oldMsgs => [...oldMsgs, data.args]);
		soundNotification.playNewMsg();
	});

	chatAPI.managerMsgsMarkedDeletion(data => {
		const array = data.args.msgs_ids;
		messagesStore.update(oldMsgs => {
			array.forEach(id => {
				const index = Object.keys(oldMsgs).filter((key) => oldMsgs[key].msg_id === id);
				oldMsgs[+index].status = 'delete';
				oldMsgs[+index].type = 3;
				oldMsgs[+index].text = '6;';
			})
			return [...oldMsgs];
		});
	});

	chatAPI.managerDeleteHistoryMessages(data => {
		const { msg_id } = data.args;
		messagesStore.update(oldMsgs => {
			const index = Object.keys(oldMsgs).filter((key) => oldMsgs[key].msg_id === msg_id);
			const msg = {
				msg_id: '5345435',
				sender_id: 'CRM',
				text: '7;',
				timestamp: 'none',
				status: 'read',
				type: 3
			};
			oldMsgs.splice(+index + 1, 0, msg);
			return [...oldMsgs];
		});
	});

	chatAPI.msgClient(data => {
		data.args.sender_id = 'client_msg';
		messagesStore.update(oldMsgs => [...oldMsgs, data.args]);
	});

	chatAPI.managerEditedMsg(data => {
		data.args.sender_id = 'CRM';
		messagesStore.update((allMsgs) => {
			let msg = allMsgs.find(msg => msg.msg_id == data.args.msg_id);
			msg.type = 4;
			msg.text = data.args.text;
			return [...allMsgs];
		});
	})

	chatAPI.managerOffer(data => {
		if(navigator.mediaDevices != undefined) {
			navigator.mediaDevices.getUserMedia({audio: true}).then(function() {
				caller.gotManagerOfferCb(data.args);
				soundNotification.startRinging();
				callStateStore.set(CallerStateType.CONNECTING);
			}).catch(function() {
				caller.gotManagerOfferCb(data.args);
				chatAPI.sendClientOfferDecline();
				callStateStore.set(CallerStateType.NO_MICROPHONE_ACCESS);
			})
		} else {
			callStateStore.set(CallerStateType.NO_MICROPHONE_ACCESS);
		}
	});

	chatAPI.managerStopCall(() => {
		caller.hangUp();
		soundNotification.stopRinging();
		callStateStore.set(CallerStateType.STAND_BY);
		microphoneStateStore.set(true);
	});

	chatAPI.resetStateOtherWindows(() => {
		caller.hangUp();
		soundNotification.stopRinging();
		callStateStore.set(CallerStateType.STAND_BY);
	});

	caller.setSendClientIceCandidate(chatAPI.sendClientIceCandidate);
	caller.setSendClientAnswerMsg(chatAPI.sendClientAnswerMsg);

	chatAPI.managerIce(caller.gotManagerICECandidate);

	setupI18n(lang);
	warning = $_('app.warningMicrophone');
</script>

<div class="container">
	{#if stateChatUI}
		<Chat {style}/>
	{:else}
		<IconChat {style}/>
	{/if}
	{#if callStateChat === CallerStateType.NO_MICROPHONE_ACCESS}
		<Warning {warning}/>
	{/if}
</div>
